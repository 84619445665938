import { makeVar } from '@apollo/client';
  
export const operatorListVar = makeVar([]);

export const operatorListCache = {
    get: () => {
        return operatorListVar();
    },
    set: (data) => {
        let newData = [];
        for(const item of data){
            newData.push({
                operatorID:item.operatorID,
                name:item.name,
                surname:item.surname,
                nameInChatbox:item.nameInChatbox,
                shortcut:item.shortcut,
                img:item.img,
                color:item.color,
                status:item.status
            })
        }
        operatorListVar(newData);
    },
    getItemByID: (operatorID) => {        
        const allOperators = operatorListVar();
        
        for(const operator of allOperators){
            if(operator.operatorID == operatorID){
                return operator;
            }
        }
        return null;
    },
    addItem: (data) => {        
        operatorListVar([...operatorListVar(),{
            operatorID:data.operatorID,
            name:data.name,
            surname:data.surname,
            nameInChatbox:data.nameInChatbox,
            shortcut:data.shortcut,
            img:data.img,
            color:data.color,
            status:data.status
        }]);
    },
    addItemIfNotExist: (data) => {     
        
        const allItems  = operatorListVar();
        
        const itemExist = allItems.filter(function (value, index) {
            return (value.operatorID == data.operatorID);
        });

        if(itemExist.length == 0){
            operatorListVar([...operatorListVar(),{
                operatorID:data.operatorID,
                name:data.name,
                surname:data.surname,
                nameInChatbox:data.nameInChatbox,
                shortcut:data.shortcut,
                img:data.img,
                color:data.color,
                status:data.status
            }]);
        }

    },
    editItem: (operatorID,data) => {   
        let items = operatorListVar();
        for(const key in items){
            if(items[key].operatorID == operatorID){
                items[key] = {
                    ...items[key],
                    ...(data.operatorID 	&& { operatorID: data.operatorID }),
                    ...(data.name 		    && { name: data.name }),
                    ...(data.surname 		&& { surname: data.surname }),
                    ...(data.nameInChatbox  && { nameInChatbox: data.nameInChatbox }),                    
                    ...(data.shortcut 		&& { shortcut: data.shortcut }),
                    ...(data.img 		    && { img: data.img }),
                    ...(data.color 	        && { color: data.color }),
                    ...(data.status 	    && { status: data.status }),
                }
            }
        }  
        operatorListVar([...items]);
    },
    removeItemByOperatorID: (operatorID) => {        
        
        const allItems = operatorListVar();
        const newItems = allItems.filter(function (value, index) {
            return (value.operatorID != operatorID);
        });
        
        operatorListVar(newItems);
    }
}