import React,{ Component, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import translate from '../../Modules/Translations/index';

const Dropdown = (props) => {

	  const dropdownRef 				                  = useRef();
    const [dropdownOpen,setDropdownOpen] 	      = useState(false);
	  useOnClickOutside(dropdownRef, () => openCloseDropdown(false));

    const clearDropdown = (e) => {
      e.preventDefault();
      e.stopPropagation();
      !!props.onCleared && props.onCleared();
    }

    const openCloseDropdown = (open) => {
      setDropdownOpen(open);
      if(open != dropdownOpen){
        props.onOpenChanged && props.onOpenChanged(open);
      }
    }

    return (   
      <div className={`dropdown ${!!props.className ? props.className : ""}`} onClick={() => openCloseDropdown(!dropdownOpen)}  ref={dropdownRef}>
        <div className='text'>{props.content}</div>
        {props.showDropdown &&
          <div className='addon'>
            <div className='separator'></div>
            {!props.showRemove ?
              <i className="icon las la-angle-down"></i>
              :
              <i className="icon las la-times-circle" onClick={(e) => clearDropdown(e)} ></i>
            }
          </div>
        }
        {(!!props.data && props.data.length > 0 || !!props.dropdownContent) &&
          <div className={'dropdown-content ' + (dropdownOpen ? "show" : "")} style={{width: (!!props.contentWidth ? props.contentWidth : "auto")}}>
              {!props.dropdownContent ?
                <>
                  {props.data.map((item,index) => (
                    <div className='dropdown-item' key={index} onClick={() => !!props.onSelected && props.onSelected(item)}>									
                      <div className='text'>{props.contentSelector(item)}</div>
                    </div>
                  ))}
                </>
                :
                <>{props.dropdownContent}</>
              }
              <div className='arrow'style={{top: (!!props.arrowTop ? props.arrowTop : "-0.5rem"),right: (!!props.arrowRight ? props.arrowRight : "auto"),bottom: (!!props.arrowBottom ? props.arrowBottom : "auto"),left: (!!props.arrowLeft ? props.arrowLeft : "50%"),}}></div>
          </div>
        }
      </div>
    );
}


export default Dropdown;
