import { makeVar } from '@apollo/client';
  
export const accountVar = makeVar({
    status:"unknown",
    accountID: null
});

export const accountCache = {
    get: () => {
        return accountVar();
    },
    set: (data) => {
        const newData = { 
            ...(data.status 		&& { status: data.status }),
            ...(data.accountID 	    && { accountID: data.accountID }),
        }
        accountVar({...accountVar(),...newData});
    }
}