import cookies from "./cookies";
import Websocket, { startWebSocket } from "../Modules/Websocket/websocket";
import { v4 as uuidv4 } from "uuid";
import ShortUniqueId from "short-unique-id";
import { getUrlParams } from "./helper";
import moment from "moment";
import { initialParamsCache } from "./Cache/initialParamsCache";
import { accountCache } from "./Cache/accountCache";
import { visitorCache } from "./Cache/visitorCache";
import messageEmiters from "../Modules/Websocket/Emiters/messageEmiters";
import { chatboxCache } from "./Cache/chatboxCache";
import { messageListCache } from "../Library/Cache/messageListCache";
import { operatorListCache } from "../Library/Cache/operatorListCache";
import { conversationCache } from "../Library/Cache/conversationCache";
import translate from "../Modules/Translations/index";
import visitorEmiters from "../Modules/Websocket/Emiters/visitorEmiters";

export function bootstrapApp(referrer, url, pageTitle) {
    let visitorID = getUrlParams().visitorID;
    let chatboxID = getUrlParams().chatboxID;

    if (!visitorID || visitorID == "") {
        const uid = new ShortUniqueId({ length: 16 });

        visitorID = uid.rnd();
        cookies.set("pptl_visitorID", visitorID, 365);
    }

    visitorCache.set({ visitorID });
    chatboxCache.set({ chatboxID });
    initialParamsCache.set({ referrer, url, pageTitle });

    //loads all fonts before display app content to prevent FOUT (Flash of Unstyled Text)
    document.fonts.load("12px ABC Normal Medium").then(() => {
        document.fonts.load("600 12px ABC Normal").then(() => {
            document.fonts.load("12px ABC Normal").then(() => {
                document.fonts.load("900 12px Line Awesome Free").then(() => {
                    console.log("starting web socket");
                    startWebSocket((data) => {
                        if (!data.ipBlocked) {
                            window.parent.postMessage(["setChatboxPosition", data.chatboxData.placement], "*");
                            translate.locale = data.chatboxData.lang;
                            visitorCache.set(data.visitorData);
                            conversationCache.set(data.conversationData);
                            conversationCache.setOperators(data.conversationData.operators);
                            accountCache.set({ accountID: data.accountData.accountID, status: data.accountData.status });
                            messageListCache.set(data.messageList);
                            operatorListCache.set(data.operatorList);
                            setTimeout(() => chatboxCache.set({ ...data.chatboxData, isOpened: !!data.visitorData?.chatOpened, initiated: true }), 100);
                        }
                    });
                });
            });
        });
    });
}

export function requestForIntroductionSystemMessage() {
    const messageList = messageListCache.get();
    const chatboxData = chatboxCache.get();
    const visitorData = visitorCache.get();

    if (messageList.length == 0 && !!chatboxData.introductionEnabled && !visitorData.isIntroduced) {
        visitorEmiters.requestIntroductionSystemMessage();
    }
}

/**
 * If message is of thype to play sound
 */
export function isSoundMessage(messageData) {
    if ([1].includes(messageData.messageTypeID) && !!messageData.fromOperator) {
        return true;
    }
    return false;
}

/**
 * Sets unread message count based on new received message
 * @param {Object} messageData  - message data from websocket
 *
 */
export function setsUnreadMessageCount(messageData) {
    if ([1].includes(messageData.messageTypeID) && messageData.fromOperator == 1) {
        const visitorData = visitorCache.get();
        visitorCache.set({ unreadMessageCount: visitorData.unreadMessageCount + 1 });
    }
}

/**
 * sets unread messages in conversation as read
 * @param {String} visitorID
 */
export function setMessagesRead() {
    messageEmiters.messagesRead();
    visitorCache.set({ unreadMessageCount: 0 });
}
