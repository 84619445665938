import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import translate from '../Translations/index';
import Modal from 'react-modal';
import { addNotification } from '../../Library/helper';

function UploadFilesDropzone(props,ref) {

  const windowRef                                         = useRef(null);
  const isDragging                                        = useRef(false);
  const [dropzoneVisible,setDropzoneVisible]              = useState(false);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({    
    maxSize:4000000, //bytes (4Mb)
    onDrop: (acceptedFiles) => {
      props.onDrop && props.onDrop(acceptedFiles);
    },
    onDropRejected: (fileRejections,event) => {

      let message = "";
      let i = 0;
      for(let item of fileRejections){
        message += (i == 0 ? "" : "\n") + "- " + item.file.path;
        i++;
      }

      addNotification("danger",message,translate.t("RejectedFiles"));

    },
    maxFiles:0,
    noClick:true,
    accept: '.pdf, application/pdf, application/msword, .doc, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/gif, image/jpg, image/jpeg, image/png, image/bmp'   
  });

  useEffect(() => {
      document.addEventListener("dragenter", dragEnter);
      document.addEventListener("dragleave", dragLeave);
      document.addEventListener("drop", drop);
      return () => {
        document.removeEventListener("dragenter", dragEnter);
        document.removeEventListener("dragleave", dragLeave);
        document.removeEventListener("drop", drop);
      };
  }, []);

  const dragEnter = (event) => {
      windowRef.current   = event.target;
      isDragging.current  = true;
      setDropzoneVisible(true);
  }
  const dragLeave = (event) => {
    if (windowRef.current == event.target){   
      setDropzoneVisible(false);  
      isDragging.current  = false;
    }
  }
  const drop = () => {
    setDropzoneVisible(false);  
  }

  const openFileSelector = ()=>{
    open()    
  }
    
  useImperativeHandle(ref, () => ({
    openFileSelector:openFileSelector
  }));
    

  return (
    <>
      <div className={"dropzone " + (dropzoneVisible ? "visible " : "") + (isDragAccept ? "dragAccepted " : "") + (isDragReject ? "dragRejected " : "")} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='drop-files-here'>{translate.t("DropFilesHere")}</div>
      </div>      
    </>
  );

  
}

export default forwardRef(UploadFilesDropzone);