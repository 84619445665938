import { getApolloClient } from '../../../Library/apolloClient';
import { conversationCache } from '../../../Library/Cache/conversationCache';
import { EVENTS_CONVERSATION } from '../Library/conversationEvents';

  const registerConversationHandlers = (socket) => {

    const client = getApolloClient();


    /**
     * Visitor's conversation activated
     * @param {Object} data - websocket data 
     */
     const conversationStarted = (data) => {    
        conversationCache.set({conversationStateID:data.conversation.conversationStateID});        
    } 

    /**
     * Visitor's conversation paused
     * @param {Object} data - websocket data 
     */
    const conversationPaused = (data) => {    
        conversationCache.set({conversationStateID:data.conversation.conversationStateID});
    } 

    /**
     * Visitor's conversation closed
     * @param {Object} data - websocket data 
     */
    const conversationClosed = (data) => {    
          conversationCache.set({conversationStateID:data.conversation.conversationStateID});
    } 


  
    /**
     * Join websocket events with functions 
     */
    socket.on(EVENTS_CONVERSATION.CONVERSATION_STARTED,conversationStarted);
    socket.on(EVENTS_CONVERSATION.CONVERSATION_PAUSED,conversationPaused);
    socket.on(EVENTS_CONVERSATION.CONVERSATION_CLOSED,conversationClosed);
    

  }


export default registerConversationHandlers;