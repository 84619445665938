import React, {useEffect, useState} from 'react';
import ChatBox from './Modules/Chatbox/Components/Chatbox';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { getApolloClient } from './Library/apolloClient';


const App = (props) => {
  
  const client = getApolloClient();

  return ( 
    <ApolloProvider client={client}>     
        <ChatBox referrer={props.referrer} url={props.url} pageTitle={props.pageTitle} />
        
    </ApolloProvider>
  );
    
}

export default  App;