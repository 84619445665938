import { getWebSocket } from "../websocket";
import { EVENTS_CONVERSATION } from "../Library/conversationEvents";

export default {

  startConversation:(conversationID,visitorID) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        let data = {
          conversationID,
          operatorID:null,
          visitorID
        }
        socket.emit(EVENTS_CONVERSATION.CONVERSATION_STARTED,data,(data) => {
          console.log(data);
        });  

        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  },
  closeConversation:(conversationID,visitorID) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        let data = {
          conversationID,
          operatorID:null,
          visitorID
        }
        socket.emit(EVENTS_CONVERSATION.CONVERSATION_CLOSED,data,(data) => {
          console.log(data);
        });  

        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  }
  
}
