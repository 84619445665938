/**
 *  Component for render messages
 */
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { getMessageTimeString } from '../../../Library/helper';
import translate from '../../Translations/index';
import useOnClickOutside from 'use-onclickoutside';
import { messageListCache } from '../../../Library/Cache/messageListCache';
import { conversationCache } from '../../../Library/Cache/conversationCache';
import { ReactComponent as ReadIcon } from '../../../Public/Images/message_read.svg';
import { ReactComponent as ReadByAllIcon } from '../../../Public/Images/message_read_all.svg';

const ReadBy = (props) => {
   
    const {message}     = props;

    let operators          = [...conversationCache.getOperators()];
    
	const ref 			  = useRef();
	useOnClickOutside(ref, () => showHideReadByContent(false));
    
    const getReadBy = () => {

        //check if all operators read message
        for(let operator of operators){
            operator.read = false;
            for(let readBy of message.readBy){
                if(!!readBy.operatorID && readBy.operatorID == operator.operatorID){ 
                    return readBy.readDate             
                }
            }
        }
        
        return false;
    }

    const showHideReadByContent = (status) => {
        messageListCache.editItem(message.messageID,{showReadByDetail:typeof status != "undefined" ? status : !message.showReadByDetail});
    }

    const readBy = getReadBy();
    return(
        <>
            {message.readBy.length > 0 &&
                <div ref={ref} className='read-by' onClick={() => showHideReadByContent()}>                
                    {translate.t("Read") + " " + getMessageTimeString(readBy.readDate,true)}
                    {!!getReadBy() && <ReadIcon className="read-by-icon"/>}
                    {!!message.showReadByDetail &&
                        <div className='read-by-content'>
                            {message.readBy.map((item,index) => {
                                const nameData = conversationCache.getOperatorByID(item.operatorID);
                                return (
                                    <div className='item'>
                                        <div className='name'>{nameData.name + " " + nameData.surname}</div>
                                        <div className='date-time' title={moment.utc(item.readDate).local().format("L LT")}>{getMessageTimeString(item.readDate,true)}</div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                
                </div>
            }
        </>
    );

}

export default ReadBy;