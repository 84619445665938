
const prefix = 'operator';
export const EVENTS_OPERATOR = {
  DISCONNECTED: `disconnect`,
  CONNECTED: `${prefix}.connected`,
  TYPING: `${prefix}.typing`,
  CONVERSATION_JOINED: `${prefix}.conversation_joined`,
  CONVERSATION_LEAVED: `${prefix}.conversation_leaved`,
  STATUS_CHANGED: `${prefix}.status_changed`,
}; 
