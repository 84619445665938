import { makeVar } from '@apollo/client';
  
export const messageListVar = makeVar([]);

export const messageListCache = {
    get: () => {
        return messageListVar();
    },
    set: (data) => {
        let newData = [];
        for(const item of data){
            newData.push({
                messageID:item.messageID,          
                messageTypeID:item.messageTypeID,
                text:item.text,
                fromOperator:item.fromOperator,
                operatorID:item.operatorID,
                visitorID:item.visitorID,
                createdDate:item.createdDate,
                deliveredDate:item.deliveredDate,
                readBy:item.readBy,
                showReadByDetail:false,
                ratingSelected:item.ratingSelected,
                ratedValue:item.ratedValue,
                files:item.files ? item.files : []
            })
        }
        messageListVar(newData);
    },
    addItem: (data) => {        
        messageListVar([...messageListVar(),{
            messageID:data.messageID,          
            messageTypeID:data.messageTypeID,
            text:data.text,
            fromOperator:data.fromOperator,
            operatorID:data.operatorID,
            visitorID:data.visitorID,
            createdDate:data.createdDate,
            deliveredDate:data.deliveredDate,
            readBy:data.readBy,
            showReadByDetail:false,
            ratingSelected:data.ratingSelected,
            ratedValue:data.ratedValue,
            files:data.files ? data.files : []
        }]);
    },
    editItem: (messageID,data) => {   
        let items = messageListVar();
        for(const key in items){
            if(items[key].messageID == messageID){
                items[key] = { 
                    ...items[key],
                    ...(data.messageID 		&& { messageID: data.messageID }),
                    ...(data.messageTypeID 	&& { messageTypeID: data.messageTypeID }),
                    ...(data.text 		    && { text: data.text }),
                    ...(data.fromOperator   && { fromOperator: data.fromOperator }),
                    ...(data.createdDate 	&& { createdDate: data.createdDate }),
                    ...(data.deliveredDate 	&& { deliveredDate: data.deliveredDate }),
                    ...(typeof data.ratingSelected != "undefined" 	&& { ratingSelected: data.ratingSelected }),
                    ...(typeof data.ratedValue != "undefined" 	&& { ratedValue: data.ratedValue }),
                    ...(data.readBy 	    && { readBy: data.readBy }),
                    ...(data.files 	        && { files: data.files }),                    
                    ...(typeof data.showReadByDetail != "undefined" 		&& { showReadByDetail: data.showReadByDetail })
                }
            }
        }     
        messageListVar([...items]);
    },  
    addReadByItems: (readMessageArray) => {   

        const messages = messageListVar();

        for(let message of messages){
            for(let readMessage of readMessageArray){
                if(message.messageID == readMessage.messageID){
                    message.readBy.push({
                        visitorID:readMessage.visitorID,
                        operatorID:readMessage.operatorID,
                        readDate:readMessage.readDate,
                    });
                }
            }    
        }
        messageListVar([...messages]);
    },    
    removeItemByMessageID: (messageID) => {        
        
        const allItems = messageListVar();
        const newItems = allItems.filter(function (value, index) {
            return (value.messageID != messageID);
        });
        
        messageListVar(newItems);
    }
}