import { io } from "socket.io-client";
//import jwt from "jsonwebtoken";
import registerVisitorHandlers from "./EventHandlers/visitorHandlers";
import registerMessageHandlers from "./EventHandlers/messageHandlers";
import registerAccountHandlers from "./EventHandlers/accountHandlers";
import registerOperatorHandlers from "./EventHandlers/operatorHandlers";
import registerConversationHandlers from "./EventHandlers/conversationHandlers";

let webSocket;

/**
 * Starts socketIO
 *
 * @param {connectedCallback} callback - A callback returning visitor connected result. Callback has one param "data" containing visitor's data
 */
export const startWebSocket = (connectedCallback) => {
    webSocket = new Websocket(connectedCallback);
};

/**
 * Returns instance of Websocket class
 * @returns instance of Websocket class
 */
export const getWebSocket = () => {
    return webSocket;
};

export default class Websocket {
    constructor(connectedCallback) {
        this.connectedCallback = connectedCallback;

        this.socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
            transports: ["websocket"],
            /**
             * 
             * TO-DO:
            *  Nastavit autentikaci s tokenem až se vyřeší problém s jsonwebtoken zde:
            * https://github.com/facebook/create-react-app/issues/12072#issuecomment-1118808278
            
            auth: {
                token: this.getToken(); //5 minutes expiration
            }
                */
            //reconnection:false
        });

        this.socket.on("connect_error", (err) => {
            /**
            * 
            * TO-DO:
            *  Nastavit autentikaci s tokenem až se vyřeší problém s jsonwebtoken zde:
            * https://github.com/facebook/create-react-app/issues/12072#issuecomment-1118808278
           
            this.socket.auth.token = this.getToken();
            */
            console.log(err.message);
            //unable to connect to socketIO server
            if (err.message == "websocket error") {
                //addNotification("danger",translate.t("ConnectionToServerFailedReconnecting"),translate.t("Error"));
                //token expired error
            } else if (!!err.data && err.data.code == 401) {
                //addNotification("danger",translate.t("UnauthorizedAccessMakingNewConnection"),translate.t("Error"));
                this.socket.disconnect();
                this.socket.connect();
            }
        });
        /*
        this.operatorSocket = io(process.env.REACT_APP_WEBSOCKET_URL + "/operators",{        
            transports: ["websocket"]
        });
        */
        this.registerHandlers();
    }

    /**
     * 
     * TO-DO:
     * Nastavit autentikaci s tokenem až se vyřeší problém s jsonwebtoken zde:
     * https://github.com/facebook/create-react-app/issues/12072#issuecomment-1118808278
    
    getToken = () => {
        return jwt.sign({ signedFrom: "widget" }, process.env.REACT_APP_JWT_SOCKETIO_SECRET_KEY, { expiresIn: "1m" }); //5m - 5 minutes expiration
    };
    */

    registerHandlers = () => {
        registerConversationHandlers(this.socket);
        registerVisitorHandlers(this.socket, this.connectedCallback);
        registerMessageHandlers(this.socket);
        registerAccountHandlers(this.socket);
        registerOperatorHandlers(this.socket);
    };

    close = () => {
        this.socket.close();
    };
}
