import gql from 'graphql-tag';

export const UPLOAD_FILES = gql`
    mutation UploadFiles($addFilesData:AddFilesData) {
        uploadFiles(addFilesData:$addFilesData){
            fileID
            tmpID
            accountID
            name
            size
            ico
        }
    }
`;  