import { getWebSocket } from "../websocket";
import { visitorCache } from "../../../Library/Cache/visitorCache";
import { EVENTS_VISITOR } from "../Library/visitorEvents";
import { conversationCache } from "../../../Library/Cache/conversationCache";
import { chatboxCache } from "../../../Library/Cache/chatboxCache";

export default {

  typing:(isTyping) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        const conversationData			= conversationCache.get();
        let typingData = {
          conversationID:conversationData.conversationID,
          isTyping,
        }
        
        socket.emit(EVENTS_VISITOR.TYPING,typingData,(data) => {});  

        resolve();
          
      } catch (error) {
        reject(error);
      }

    });   
  },

  changeChatOpenState:(isOpen) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        const visitorData			= visitorCache.get();
        let stateData = {
          visitorID:visitorData.visitorID,
          isOpen,
        }
        
        socket.emit(EVENTS_VISITOR.CHAT_OPEN_STATE_CHANGED,stateData,(data) => {});  

        resolve();
          
      } catch (error) {
        reject(error);
      }

    });   
  },

  introduce:(name,email,phone) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        const visitorData			= visitorCache.get();
        let introductionData = {
          visitorID:visitorData.visitorID,
          name,
          email,
          phone
        }
        
        socket.emit(EVENTS_VISITOR.INTRODUCE,introductionData,(data) => {});  

        resolve();
          
      } catch (error) {
        reject(error);
      }

    });   
  },
  requestIntroductionSystemMessage: () => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        const conversationData  = conversationCache.get();
        const visitorData			  = visitorCache.get();
        const chatboxData			  = chatboxCache.get();

        let requestData = {
          visitorID:visitorData.visitorID,
          conversationID:conversationData.conversationID,
          chatboxID:chatboxData.chatboxID
        }
        
        socket.emit(EVENTS_VISITOR.REQUEST_INTRODUCTION_SYSTEM_MESSAGE,requestData,(data) => {});  

        resolve();
          
      } catch (error) {
        reject(error);
      }

    });   
  },

}
