import { useReactiveVar } from '@apollo/client';
import translate from '../../Translations/index';
import React from 'react';
import { accountVar } from '../../../Library/Cache/accountCache';
import { chatboxVar } from '../../../Library/Cache/chatboxCache';
import { visitorVar } from '../../../Library/Cache/visitorCache';
import { ReactComponent as Logo } from '../../../Public/Images/logo.svg';

const ChatClosed = (props) => {    
    const accountData = useReactiveVar(accountVar);
    const visitorData = useReactiveVar(visitorVar);    
    const chatboxData = useReactiveVar(chatboxVar);

    return (
        <div className="chat-closed" style={{backgroundColor:chatboxData.mainColor}}>
                <div className='content'>
                    <Logo className="logo" />
                    <div className='text'>{translate.t("Support")}</div>
                </div>
                {!!accountData && accountData.status != "unknown" &&
                    <div className={"status " + (accountData.status === "online" ? "online" : "offline")}></div>
                }
                {visitorData.unreadMessageCount > 0 &&
                    <div className='unread-messages-count'>{visitorData.unreadMessageCount}</div>
                }
		</div>
    );
}

export default ChatClosed;
