import { chatboxCache } from '../../../Library/Cache/chatboxCache';
import { conversationCache } from '../../../Library/Cache/conversationCache';
import { operatorListCache } from '../../../Library/Cache/operatorListCache';
import { EVENTS_OPERATOR } from '../Library/operatorEvents';

  const registerOperatorHandlers = (socket) => {


      /**
       * Operator typing start/stop
       * @param {Object} data - websocket data 
       */
      const typing = (data) => {
          if(data.isTyping){
            chatboxCache.addTypingOperator(data.operatorID);
          }else{
            chatboxCache.removeTypingOperator(data.operatorID);
          }   
      } 
    
      /**
       * Operator joined conversation
       * @param {Object} data - websocket data 
       */
      const conversationJoined = (data) => {      
          conversationCache.addOperatorItemIfNotExist(data.operatorData);        
      } 

      /**
       * Operator leaved conversation
       * @param {Object} data - websocket data 
       */
      const conversationLeaved = (data) => {     
        conversationCache.removeOperatorItem(data.operatorID);
      }  
      
      /**
       * Operator changed status
       * @param {Object} data - websocket data 
       */
      const statusChanged = (data) => {   
        //TO-DO - zrušit setTimeout až (pokud) se udělá odložené odhlášení operátora stejně jako u visitora
        setTimeout(() => {  
          operatorListCache.editItem(data.operatorID,{status:data.status});     
          conversationCache.editOperator(data.operatorID,{status:data.status});
        }, 100);
      } 
      
    
      
      socket.on(EVENTS_OPERATOR.TYPING, typing);
      socket.on(EVENTS_OPERATOR.CONVERSATION_JOINED,conversationJoined);
      socket.on(EVENTS_OPERATOR.CONVERSATION_LEAVED,conversationLeaved);
      socket.on(EVENTS_OPERATOR.STATUS_CHANGED,statusChanged);
  }

export default registerOperatorHandlers;