
const prefix = 'visitor';
export const EVENTS_VISITOR = {
  DISCONNECTED: `${prefix}.disconnected`,
  CONNECTED: `${prefix}.connected`,
  TYPING: `${prefix}.typing`,
  CHAT_OPEN_STATE_CHANGED: `${prefix}.chat_open_state_changed`,
  INTRODUCE: `${prefix}.introduce`,
  EDIT: `${prefix}.edit`,
  REQUEST_INTRODUCTION_SYSTEM_MESSAGE: `${prefix}.request_introducion_system_message`,
}; 
