import { makeVar } from '@apollo/client';
  
export const chatboxVar = makeVar({
    chatboxID:null,
    lang:"en",
    initiated:false,
    isFocused:false,
    isOpened:false,
    pageWidth:null,
    pageHeight:null,
    mainColor:"",
    webUrl:"",
    placement:"right",
    systemOperatorName:"",
    systemOperatorPhoto:null,
    chatboxOnlineTitle:"",
    chatboxOnlineStatusTitle:"",
    textOnlinePlaceholder:"",
    chatboxOfflineTitle:"",
    chatboxOfflineStatusTitle:"",
    textOfflinePlaceholder:"",
    introductionEnabled:0,
    introductionText:"",
    introductionGetName:1,
    introductionNameRequired:1,
    introductionGetEmail:0,
    introductionEmailRequired:0,
    introductionGetPhone:0,
    introductionPhoneRequired:0,
    introductionShowGdpr:0,
    introductionShowGdprCheckbox:0,
    introductionGdprUrl:"",
    typingOperators:[]
});

export const chatboxCache = {
    get: () => {
        return chatboxVar();
    },
    set: (data) => {
        const newData = { 
            ...(!!data.chatboxID 		                    && { chatboxID: data.chatboxID }),
            ...(!!data.lang 		                        && { lang: data.lang }),
            ...(data.initiated 		                        && { initiated: data.initiated }),
            ...(typeof data.isFocused != "undefined" 		&& { isFocused: data.isFocused }),
            ...(typeof data.isOpened != "undefined" 		&& { isOpened: data.isOpened }),
            ...(typeof data.pageWidth != "undefined" 		&& { pageWidth: data.pageWidth }),
            ...(typeof data.pageHeight != "undefined" 		&& { pageHeight: data.pageHeight }),
            ...(!!data.mainColor 		                    && { mainColor: data.mainColor }),
            ...(!!data.webUrl 		                        && { webUrl: data.webUrl }),
            ...(!!data.placement 		                    && { placement: data.placement }),
            ...(!!data.systemOperatorName 		            && { systemOperatorName: data.systemOperatorName }),
            ...(!!data.systemOperatorPhoto 		            && { systemOperatorPhoto: data.systemOperatorPhoto }),
            ...(!!data.chatboxOnlineTitle 		            && { chatboxOnlineTitle: data.chatboxOnlineTitle }),
            ...(!!data.chatboxOnlineStatusTitle 		    && { chatboxOnlineStatusTitle: data.chatboxOnlineStatusTitle }),
            ...(!!data.textOnlinePlaceholder 		        && { textOnlinePlaceholder: data.textOnlinePlaceholder }),
            ...(!!data.chatboxOfflineTitle 		            && { chatboxOfflineTitle: data.chatboxOfflineTitle }),
            ...(!!data.chatboxOfflineStatusTitle 		    && { chatboxOfflineStatusTitle: data.chatboxOfflineStatusTitle }),
            ...(!!data.textOfflinePlaceholder 		        && { textOfflinePlaceholder: data.textOfflinePlaceholder }),
            ...(typeof data.introductionEnabled != "undefined" 		            && { introductionEnabled: data.introductionEnabled }),
            ...(!!data.introductionText 		                                && { introductionText: data.introductionText }),
            ...(typeof data.introductionGetName != "undefined" 		            && { introductionGetName: data.introductionGetName }),
            ...(typeof data.introductionNameRequired != "undefined" 		    && { introductionNameRequired: data.introductionNameRequired }),
            ...(typeof data.introductionGetEmail != "undefined" 		        && { introductionGetEmail: data.introductionGetEmail }),
            ...(typeof data.introductionEmailRequired != "undefined" 		    && { introductionEmailRequired: data.introductionEmailRequired }),
            ...(typeof data.introductionGetPhone != "undefined" 		        && { introductionGetPhone: data.introductionGetPhone }),
            ...(typeof data.introductionPhoneRequired != "undefined" 		    && { introductionPhoneRequired: data.introductionPhoneRequired }),
            ...(typeof data.introductionShowGdpr != "undefined" 		        && { introductionShowGdpr: data.introductionShowGdpr }),
            ...(typeof data.introductionShowGdprCheckbox != "undefined"         && { introductionShowGdprCheckbox: data.introductionShowGdprCheckbox }),
            ...(!!data.introductionGdprUrl 		            && { introductionGdprUrl: data.introductionGdprUrl }),
        }
        chatboxVar({...chatboxVar(),...newData});
    },
    clearTypingOperators: () => {  
        chatboxVar({...chatboxVar(),typingOperators:[]});        
    },
    addTypingOperator: (operatorID) => {    
        const allTypingOperators  = chatboxVar().typingOperators;
        
        const itemExist = allTypingOperators.filter(function (value, index) {
            return (value == operatorID);
        });

        if(itemExist.length == 0){
            chatboxVar({...chatboxVar(),
                typingOperators:[...chatboxVar().typingOperators,operatorID]
            });
        }
    },
    removeTypingOperator: (operatorID) => {        
        
        const allItems = chatboxVar().typingOperators;
        const newItems = allItems.filter(function (value, index) {
            return (value != operatorID);
        });
        
        chatboxVar({...chatboxVar(),typingOperators:[...newItems]});
    },
}