import 'react-notifications-component/dist/theme.css'
import { useReactiveVar } from '@apollo/client';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { chatboxVar } from '../../../Library/Cache/chatboxCache';
import { messageListVar } from '../../../Library/Cache/messageListCache';
import { operatorListCache } from '../../../Library/Cache/operatorListCache';
import { visitorVar } from '../../../Library/Cache/visitorCache';
import { requestForIntroductionSystemMessage, setMessagesRead } from '../../../Library/chat';
import UploadFilesDropzone from '../../GlobalComponents/UploadFilesDropzone';
import Message from './Message';
import RespondPanel from './RespondPanel';
import { ReactNotifications } from 'react-notifications-component';
import { getMessageGroupedInfo, getMessagesDateSeparator, openLinkInParentWindow } from '../../../Library/helper';
import { conversationVar } from '../../../Library/Cache/conversationCache';
import AvatarOperator from '../../GlobalComponents/AvatarOperator';
import { accountVar } from '../../../Library/Cache/accountCache';
import Dropdown from '../../GlobalComponents/Dropdown';
import translate from '../../Translations/index';
import { ReactComponent as Logo } from '../../../Public/Images/logo_colored_titled.svg';
import ModalNotification from '../../GlobalComponents/ModalNotification';
import conversationEmiters from '../../Websocket/Emiters/conversationEmiters';
import ReactTooltip from 'react-tooltip';
import IntroductionForm from './IntroductionForm';

const ChatOpened = (props) => {
	
	const messageList 							= useReactiveVar(messageListVar);
    const visitorData 							= useReactiveVar(visitorVar);
    const conversationData						= useReactiveVar(conversationVar);
    const accountData 							= useReactiveVar(accountVar);
    const chatboxData 							= useReactiveVar(chatboxVar);
	
	const [isTyping,setIsTyping]						= useState(false);
	const [typingTimeoutID,setTypingTimeoutID]			= useState(null);
	const [showCloseChatModal,setShowCloseChatModal]	= useState(false);

    const messagesEndRef    					= useRef(null);
    const dropzoneRef                           = useRef(null);
    const respondPanelRef                       = useRef(null);
    const displayedMessageCount                 = useRef(null);

    let lastMessageDate                                 = null;
	
    const scrollToBottom = () => {		
        if(displayedMessageCount.current != messageList.length){
			messagesEndRef.current.scrollIntoView();
            displayedMessageCount.current = messageList.length;
        }
    }
    useEffect(() => {
		scrollToBottom();
		ReactTooltip.rebuild();
	}, [messageList]);

	useEffect(() => {
		if(visitorData.unreadMessageCount > 0){
			setMessagesRead();
		}
		requestForIntroductionSystemMessage();
	},[]);
	
    useEffect(() => {
        function visibilityChanged(){
            if(!document.hidden){
				setMessagesRead();
            }
        }

        //event listener for tab change or close browser events
        document.addEventListener("visibilitychange", visibilityChanged);        
        return () => document.removeEventListener("visibilitychange", visibilityChanged);
    },[]);

	const close = () => {
		if(conversationData.conversationStateID == 2){
			setShowCloseChatModal(true);
		}else{
			!!props.onClosed && props.onClosed();
		}
	}
	const closeChat = (state) => {
		if(state){
			conversationEmiters.closeConversation(conversationData.conversationID,visitorData.visitorID);
			!!props.onClosed && props.onClosed();
		}
		setShowCloseChatModal(false);
	}
	
    return (
        <div className={`chat-opened`} style={{...(chatboxData.pageWidth < 576 && { width: chatboxData.pageWidth + "px" })}}>
        	<div className="content">
				<div className="header" style={{backgroundColor:chatboxData.mainColor}}>
					<div className='operator-list'>
						{conversationData.operators.slice(0, 2).map((item,index) => (
							<AvatarOperator key={index} showStatus={false} operatorData={item} statusClass="border white-border"/>							
						))}
						{conversationData.operators.length > 2 &&
							<div className='avatar plus'><div className='avatar-content'>+{conversationData.operators.length - 2}</div></div>
						}
					</div>
					<div className="text-block">
						<div className='main-title' title={'Jak můžeme pomoct?'}>{accountData.status === "online" ? chatboxData.chatboxOnlineTitle : chatboxData.chatboxOfflineTitle}</div>
						<div className='subtitle' title={'Odpovíme než řeknete švec'}><span className={"status " + (accountData.status === "online" ? "online" : "offline")}></span> {accountData.status === "online" ? chatboxData.chatboxOnlineStatusTitle : chatboxData.chatboxOfflineStatusTitle}</div>
					</div>
					<div className='menu'>
						<Dropdown 
							content={<i className="las la-bars icon"></i>} 			
							arrowLeft="auto"	
							arrowRight="2.2rem"
							dropdownContent={
								<>
									{conversationData.conversationStateID == 2 &&
										<div className='dropdown-item'  onClick={() => close()}>{translate.t("CloseChat")}</div>											
									}
									<div className='dropdown-item'>{translate.t("SendConversationToEmail")}</div>	
										<div className='dropdown-separator'></div>	
									<div className='dropdown-item'>{translate.t("GDPRInfo")}</div>	
									<a href='' className='powered-by' onClick={() => openLinkInParentWindow('https://poptula.dev',true)}>
										<span className='description'>{translate.t("PoweredByDescription")}</span>
										<Logo />
									</a>
								</>
							}
						/>
						<i className="las la-arrow-down icon" onClick={() => props.onClosed()}></i>
						<i className="las la-times icon" onClick={() => close()}></i>
					</div>
				</div>		
				<div className="message-list">
					<div className="message-list-content">
						{messageList && messageList.map((item,index) => {  
							 const messagesDateSeparator = getMessagesDateSeparator(lastMessageDate,item.createdDate);
							 lastMessageDate = item.createdDate;
							 return(
								 <Fragment key={index}>
									 {messagesDateSeparator}
									 <Message message={item} groupedInfo={getMessageGroupedInfo(messageList,index)} />
								 </Fragment>  
							 ) 
						})}
						<div ref={messagesEndRef} />
					</div>
					{chatboxData && chatboxData.typingOperators && chatboxData.typingOperators.length > 0 && 
						<div className='typing-list'>
							{chatboxData.typingOperators.map((operatorID,index) => {
								return (
									<>
										<AvatarOperator key={index} operatorData={operatorListCache.getItemByID(operatorID)} statusClass="border white-border"/>	
										<i className="las la-pencil-alt typing-icon"></i>
										<div className='typing-text'>{translate.t("IsNowTyping")}...</div>
									</>
								)
							})}
						</div>

					}
				</div>

				{!!chatboxData.introductionEnabled && !visitorData.isIntroduced ? 
					<IntroductionForm />
					:
					<RespondPanel onFileSelectorOpen={() => {dropzoneRef.current.openFileSelector()}} ref={respondPanelRef} />
				}

				<UploadFilesDropzone ref={dropzoneRef} onDrop={(files) => respondPanelRef.current.uploadFiles(files)} />	
			</div>	
			
            {showCloseChatModal &&
                <ModalNotification
                    yesNo={true} 
                    content={translate.t("AreYouSureYouWantToCloseChat")} 
                    title={translate.t("CloseChat")} 
                    rejectBtnAsLink={true} 
                    confirmBtnText={translate.t("Yes")} 
                    rejectBtnText={translate.t("Continue")} 
                    callback={(status) => closeChat(status)} 
                />
            }	
			<ReactNotifications/>
		</div>
    );
}

export default ChatOpened;
