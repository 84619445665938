import { EVENTS_VISITOR } from '../Library/visitorEvents';
import { osName, osVersion, browserName, browserVersion, isMobileOnly, isTablet } from 'react-device-detect';
import { initialParamsCache } from '../../../Library/Cache/initialParamsCache';
import { chatboxCache } from '../../../Library/Cache/chatboxCache';
import { visitorCache } from '../../../Library/Cache/visitorCache';

  const registerVisitorHandlers = (socket,connectedCallback) => {

      const connected = () => {
        const chatboxData   = chatboxCache.get();
        const visitorData   = visitorCache.get();
        const initialParams = initialParamsCache.get();

        socket.emit(EVENTS_VISITOR.CONNECTED,{
          visitorID:visitorData.visitorID,
          chatboxID:chatboxData.chatboxID,
          pageTitle: initialParams.pageTitle,
          referrer: initialParams.referrer,
          url: initialParams.url,
          osName, 
          isTablet,
          isMobile:isMobileOnly,
          osVersion, 
          browserName, 
          browserVersion
        },(data) => {
          connectedCallback(data);
        });

      }
    
      const edited = (data) => {
        console.log(data);
        visitorCache.set(data.visitorData);
      }
    
      socket.on("connect", connected);
      socket.on(EVENTS_VISITOR.EDIT,edited);
  }

export default registerVisitorHandlers;