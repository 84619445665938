import { makeVar } from '@apollo/client';
  
export const conversationVar = makeVar({
    conversationID: null,
    visitorID:null,
    conversationStateID:0,
    unreadMessageCount:0,
    operators:[],
});

export const conversationCache = {
    get: () => {
        return conversationVar();
    },
    set: (data) => {
        const newData = { 
            ...(data.conversationID 		&& { conversationID: data.conversationID }),
            ...(data.visitorID 		        && { visitorID: data.visitorID }),
            ...(data.conversationStateID 	&& { conversationStateID: data.conversationStateID }),
            ...((data.unreadMessageCount || data.unreadMessageCount == 0) 	&& { unreadMessageCount: data.unreadMessageCount }),
        }
        conversationVar({...conversationVar(),...newData});
    },
    getOperators: () => {
        return conversationVar().operators;
    },
    getOperatorByID: (operatorID) => {        
        const allOperators = conversationVar().operators;
        
        for(const operator of allOperators){
            if(operator.operatorID == operatorID){
                return operator;
            }
        }
        return null;
    },
    setOperators: (data) => {
        let newData = [];
        for(const item of data){
            newData.push({
                operatorID:item.operatorID,          
                name:item.name,
                surname:item.surname,
                img:item.img,
                color:item.color,
                shortcut:item.shortcut,
                status:item.status,
            })
        }
        conversationVar({...conversationVar(),operators:newData});
    },
    editOperator: (operatorID,data) => {   
        let items = [...conversationVar().operators];
        for(const key in items){
            if(items[key].operatorID == operatorID){
                items[key] = {
                    ...items[key],                    
                    ...(!!data.name	    && { name: data.name }),
                    ...(!!data.surname	&& { surname: data.surname }),
                    ...(!!data.img	    && { img: data.img }),
                    ...(!!data.color	&& { color: data.color }),
                    ...(!!data.shortcut	&& { shortcut: data.shortcut }),
                    ...(!!data.status	&& { status: data.status }),
                }    
            }
        }  
        conversationVar({...conversationVar(),operators:[...items]});
    },
    addOperatorItemIfNotExist: (data) => {     
        
        const allItems  = conversationVar().operators;
        
        const itemExist = allItems.filter(function (value, index) {
            return (value.operatorID == data.operatorID);
        });

        if(itemExist.length == 0){
            conversationVar({...conversationVar(),
                operators:[...conversationVar().operators,{
                    operatorID:data.operatorID,          
                    name:data.name,
                    surname:data.surname,
                    img:data.img,
                    color:data.color,
                    shortcut:data.shortcut,
                    status:data.status,
                }]
            });
        }

    },
    removeOperatorItem: (operatorID) => {        
        
        const allItems = conversationVar().operators;
        const newItems = allItems.filter(function (value, index) {
            return (value.operatorID != operatorID);
        });
        
        conversationVar({...conversationVar(),operators:newItems});
    },
    operatorExists: (operatorID) => {        
        
        const allItems = conversationVar().operators;
        
        const itemExist = allItems.filter(function (value, index) {
            return (value.operatorID == operatorID);
        });

        return itemExist.length > 0;
        
    }
}