import { chatboxCache } from '../../../Library/Cache/chatboxCache';
import { messageListCache } from '../../../Library/Cache/messageListCache';
import { isSoundMessage, setMessagesRead, setsUnreadMessageCount } from '../../../Library/chat';
import { playSound } from '../../../Library/helper';
import { EVENTS_MESSAGE } from '../Library/messageEvents';

  const registerMessageHandlers = (socket) => {

      const created = (data) => {
        messageListCache.addItem(data.messageData);
        if(!chatboxCache.get().isFocused || !chatboxCache.get().isOpened || document.hidden){
          setsUnreadMessageCount(data.messageData);
        }else{
          setMessagesRead();
        }

        if(isSoundMessage(data.messageData)){
          if(chatboxCache.get().isFocused && chatboxCache.get().isOpened && !document.hidden){
            playSound("incoming-message-visible",0.5);
          }else{
            playSound("incoming-message");
          }
        }

      }
    
      /**
       * Function called when somebody read message(s)
       * @param {Object} data - emmited data 
       */
       const messagesRead = (data) => {  
        messageListCache.addReadByItems(data.allReadMessages);             
      } 

      /**
       * Function called when somebody read message(s)
       * @param {Object} data - emmited data 
       */
       const messageUpdated = (data) => {  
        messageListCache.editItem(data.messageData.messageID,data.messageData);             
      } 
    
      socket.on(EVENTS_MESSAGE.CREATED, created);
      socket.on(EVENTS_MESSAGE.MESSAGES_READ, messagesRead);
      socket.on(EVENTS_MESSAGE.MESSAGE_UPDATED, messageUpdated);
  }

export default registerMessageHandlers;