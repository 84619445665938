/**
 *  Respond panel component
 */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import translate from "../../Translations/index";
import EmoteIconPicker from "../../GlobalComponents/EmoteIconPicker";
import useOnClickOutside from "use-onclickoutside";
import visitorEmiters from "../../Websocket/Emiters/visitorEmiters";
import messageEmiters from "../../Websocket/Emiters/messageEmiters";
import { accountCache, accountVar } from "../../../Library/Cache/accountCache";
import { conversationCache } from "../../../Library/Cache/conversationCache";
import { useMutation } from "@apollo/client";
import { UPLOAD_FILES } from "../Queries/conversation";
import { addNotification } from "../../../Library/helper";
import TextareaAutosize from "react-textarea-autosize";
import { chatboxVar } from "../../../Library/Cache/chatboxCache";
import { useReactiveVar } from "@apollo/client";

const RespondPanel = (props, ref) => {
    const textareaRef = useRef(null);
    const emoteIconsRef = useRef(null);
    const [isTyping, setIsTyping] = useState(false);
    const [typingTimeoutID, setTypingTimeoutID] = useState(null);
    const [emoteIconPickerOpen, setEmoteIconPickerOpen] = useState(false);
    const [lastCaretPosition, setLastCaretPosition] = useState(0);
    const [message, setMessage] = useState("");
    const [showControls, setShowControls] = useState(false);

    const chatboxData = useReactiveVar(chatboxVar);
    const accountData = useReactiveVar(accountVar);

    const respondPanelRef = useRef();
    useOnClickOutside(respondPanelRef, () => setShowControls(false));

    useOnClickOutside(emoteIconsRef, () => setEmoteIconPickerOpen(false));

    useImperativeHandle(ref, () => ({
        uploadFiles: uploadFiles,
    }));

    useEffect(() => {
        window.addEventListener("message", onOutsideMessage);
        return function cleanup() {
            window.removeEventListener("message", onOutsideMessage, false);
        };
    }, []);

    const [uploadMessageFiles, { loading: uploadLoading }] = useMutation(UPLOAD_FILES, {
        context: { headers: { "apollo-require-preflight": true } },
        onCompleted(data) {
            let fileIDs = [];

            for (let uploadedFile of data.uploadFiles) {
                fileIDs.push(uploadedFile.fileID);
            }

            messageEmiters.create("", fileIDs);
        },
        onError(err) {
            addNotification("danger", err.message ? err.message : err.toString(), translate.t("Error"));
        },
    });

    const onOutsideMessage = (e) => {
        if (e.data[0] == "clickedOutsideChat") {
            setShowControls(false);
        }
    };
    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            if (!e.shiftKey) {
                e.preventDefault();
                sendMessage();

                !!typingTimeoutID && clearTimeout(typingTimeoutID);
                disableTyping();
            }
        } else {
            if (!isTyping) {
                setIsTyping(true);
                visitorEmiters.typing(true);
            }

            !!typingTimeoutID && clearTimeout(typingTimeoutID);
            const timeoutID = setTimeout(disableTyping, 2000);
            setTypingTimeoutID(timeoutID);
        }
    };

    const uploadFiles = (files) => {
        const conversationData = conversationCache.get();

        if (files.length > 0) {
            let filesToUpload = [];
            for (const file of files) {
                filesToUpload.push({
                    file,
                });
            }

            uploadMessageFiles({
                variables: {
                    addFilesData: {
                        accountID: accountData.accountID,
                        conversationID: conversationData.conversationID,
                        files: filesToUpload,
                    },
                },
            });
        }
    };
    const disableTyping = () => {
        setIsTyping(false);
        visitorEmiters.typing(false);
    };

    const emoteIconPicked = (icon) => {
        const newMessage = message.slice(0, lastCaretPosition) + icon + message.slice(lastCaretPosition);
        setNewMessage(newMessage);
        setTimeout(() => {
            textareaRef.current.setSelectionRange(lastCaretPosition + icon.length, lastCaretPosition + icon.length);
            textareaRef.current.focus();
        }, 100);
        setEmoteIconPickerOpen(false);
    };

    const sendMessage = () => {
        if (!sendDisabled()) {
            messageEmiters.create(message, []);
            setMessage("");
        }
    };
    const setNewMessage = (message) => {
        message = message.replace(":)", "🙂");
        message = message.replace(":-)", "🙂");
        message = message.replace(":D", "😀");
        message = message.replace(":-D", "😀");
        message = message.replace(":*", "😘");
        message = message.replace(":-*", "😘");
        message = message.replace(":/", "😕");
        message = message.replace(":-/", "😕");
        message = message.replace("XD", "🤣");
        message = message.replace("X-D", "🤣");
        message = message.replace(":O", "😮");
        message = message.replace(":‑O", "😮");
        message = message.replace(":P", "😋");
        message = message.replace(":‑P", "😋");

        setMessage(message);
    };
    const sendDisabled = () => {
        if (message.trim().length == 0 || uploadLoading) {
            return true;
        }
        return false;
    };
    const openFileSelector = () => {
        !!props.onFileSelectorOpen && props.onFileSelectorOpen();
    };

    const onTextAreaBlur = () => {
        setLastCaretPosition(textareaRef.current.selectionStart);
    };
    const onTextAreaFocus = () => {
        setShowControls(true);
    };

    return (
        <div className="respond-panel" ref={respondPanelRef}>
            <div className="text-block">
                <div className="textarea-content">
                    <TextareaAutosize
                        minRows={1}
                        maxRows={4}
                        ref={textareaRef}
                        value={message}
                        onChange={(e) => setNewMessage(e.target.value)}
                        className="w-100"
                        placeholder={accountData.status === "online" ? chatboxData.textOnlinePlaceholder : chatboxData.textOfflinePlaceholder}
                        onKeyPress={(e) => onKeyPress(e)}
                        onBlur={() => onTextAreaBlur()}
                        onFocus={() => onTextAreaFocus()}
                    />
                    {showControls && (
                        <div className={`controls`}>
                            <div className="control" onClick={() => openFileSelector()}>
                                <i className="las la-paperclip icon"></i>
                                <div className="text">{translate.t("Attachment")}</div>
                            </div>
                            <div className="control" ref={emoteIconsRef}>
                                <div className="emoteicon-control" onClick={() => setEmoteIconPickerOpen(true)}>
                                    <i className="las la-smile icon"></i>
                                    <div className="text">{translate.t("Emoteicons")}</div>
                                </div>
                                {emoteIconPickerOpen && <EmoteIconPicker onPick={(icon) => emoteIconPicked(icon)} />}
                            </div>
                            <button disabled={sendDisabled()} onClick={() => sendMessage()} className="btn blue ml-auto">
                                {translate.t("Send")}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default forwardRef(RespondPanel);
