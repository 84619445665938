/**
 *  Component for render introduction form
 */
import React, { useState } from 'react';
import translate from '../../Translations/index';
import { useReactiveVar } from '@apollo/client';
import { chatboxVar } from '../../../Library/Cache/chatboxCache';
import Checkbox from '../../GlobalComponents/Checkbox';
import { openLinkInParentWindow } from '../../../Library/helper';
import visitorEmiters from '../../Websocket/Emiters/visitorEmiters';

const IntroductionForm = (props) => {

    const chatboxData               = useReactiveVar(chatboxVar);
    const [formData,setFormData]    = useState({
        name:"",
        email:"",
        phone:"",
        gdprChackboxConfirmed:false
    })
    const [validation,setValidation]    = useState({
        name:{isValid:true,message:""},
        email:{isValid:true,message:""},
        gdprChackboxConfirmed:{isValid:true,message:""},
    })

    const openGdprLink = () => {
        openLinkInParentWindow(chatboxData.introductionGdprUrl,true);
    }

    const saveIntroduction = () => {
        if(checkValidation()){
            visitorEmiters.introduce(formData.name,formData.email,formData.phone);
        }
    }
    const checkValidation = () => {
        const validationObject = {            
            name:{isValid:true,message:""},
            email:{isValid:true,message:""},
            gdprChackboxConfirmed:{isValid:true,message:""},
        }
        if((!!chatboxData.introductionNameRequired && !formData.name)){
            validationObject.name.isValid = false;
        }
        if((!!chatboxData.introductionEmailRequired && !formData.email)){
            validationObject.email.isValid = false;
        }
        if((!!chatboxData.introductionShowGdprCheckbox && !formData.gdprChackboxConfirmed)){
            validationObject.gdprChackboxConfirmed.isValid = false;
        }
        setValidation(validationObject);

        return validationObject.name.isValid && validationObject.email.isValid && validationObject.gdprChackboxConfirmed.isValid;
    }

    return(
        
        <div className="introduction-form">    
            <div className="form">    
                {!!chatboxData.introductionGetName &&                              
                    <div className='form-group'>
                        <label>{!!chatboxData.introductionNameRequired && "*"} {translate.t("YourName")}</label>
                        <div className='input-group'>
                            <input className={`${validation.name.isValid ? "" : "invalid"}`} type="text" value={formData.name} onChange={(e) => setFormData({...formData,name:e.target.value})} />
                        </div>
                    </div>
                }        
                {!!chatboxData.introductionGetEmail &&                          
                    <div className='form-group'>
                        <label>{!!chatboxData.introductionEmailRequired && "*"} {translate.t("Email")}</label>
                        <div className='input-group'>
                            <input className={`${validation.email.isValid ? "" : "invalid"}`} type="text" value={formData.email} onChange={(e) => setFormData({...formData,email:e.target.value})} />
                        </div>
                    </div>   
                }      
                {!!chatboxData.introductionGetPhone &&                                 
                    <div className='form-group'>
                        <label>{translate.t("Phone")}</label>
                        <div className='input-group'>
                            <input type="text" value={formData.phone} onChange={(e) => setFormData({...formData,phone:e.target.value})} />
                        </div>
                    </div>  
                }   
                <div className="send-block">
                    {!!chatboxData.introductionShowGdpr &&  
                        <div className="gdpr">   
                            {!!chatboxData.introductionShowGdprCheckbox ?    
                                <div className={`gdpr-confirmation ${validation.gdprChackboxConfirmed.isValid ? "" : "invalid"}`} onClick={() => setFormData({...formData,gdprChackboxConfirmed: !formData.gdprChackboxConfirmed})}>                 
                                    <Checkbox label="" checked={formData.gdprChackboxConfirmed} />
                                    <div className="label">{translate.t("AgreeWith")}<span className="link" onClick={() => openGdprLink()}>{translate.t("GDPR")}</span></div>    
                                </div>    
                                :
                                <span className="link" onClick={() => openGdprLink()}>{translate.t("GDPR")}</span>
                            }
                        </div>
                    }   
                    <button className='btn blue' onClick={() => saveIntroduction()}>{translate.t("Save")}</button>
                </div>
            </div>
        </div>
    );

}

export default IntroductionForm;