import React from "react";
import ReactDOM from "react-dom/client";

import "./Public/Css/znackarnaFW.css";
import "./Public/Css/main.css";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { chatboxCache } from "./Library/Cache/chatboxCache";

window.onmessage = function (e) {
    if (e.data[0] == "iframeInitialized") {
        const referrer = e.data[1];
        const url = e.data[2];
        const pageTitle = e.data[3];

        const root = ReactDOM.createRoot(document.getElementById("root"));
        root.render(<App referrer={referrer} url={url} pageTitle={pageTitle} />);

        // If you want to start measuring performance in your app, pass a function
        // to log results (for example: reportWebVitals(console.log))
        // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
        reportWebVitals();
    }

    if (e.data[0] == "pageResized") {
        chatboxCache.set({ pageWidth: e.data[1], pageHeight: e.data[2] });

        if (e.data[1] < 576) {
            document.body.classList.add("mobile-body");
        } else {
            document.body.classList.remove("mobile-body");
        }
    }
};
