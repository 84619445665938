import { makeVar } from '@apollo/client';
  
export const visitorVar = makeVar({
    visitorID: null,
    name: "",
    email: "",
    phone: "",
    isIntroduced:0,
    unreadMessageCount:0
});

export const visitorCache = {
    get: () => {
        return visitorVar();
    },
    set: (data) => {
        const newData = { 
            ...(data.visitorID 		        && { visitorID: data.visitorID }),
            ...(!!data.name 		        && { name: data.name }),
            ...(!!data.email 		        && { email: data.email }),
            ...(!!data.phone 		        && { phone: data.phone }),
            ...(!!data.isIntroduced 		&& { isIntroduced: data.isIntroduced }),
            ...((data.unreadMessageCount || data.unreadMessageCount == 0) 	&& { unreadMessageCount: data.unreadMessageCount }),
        }
        visitorVar({...visitorVar(),...newData});
    }
}