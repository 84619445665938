import { getWebSocket } from "../websocket";
import moment from "moment";
import { EVENTS_MESSAGE } from "../Library/messageEvents";
import { visitorCache } from "../../../Library/Cache/visitorCache";
import { messageListCache } from "../../../Library/Cache/messageListCache";
import { conversationCache } from "../../../Library/Cache/conversationCache";

export default {

  create:(message,fileIDs) => {
    return new Promise(async(resolve,reject) => {
        try {

        const socket = getWebSocket().socket;  

        const conversationData	= conversationCache.get();
        let messageData = {
          conversationID:conversationData.conversationID,
          visitorID:conversationData.visitorID,
          operatorID:null,
          text:message,
          fromOperator:0,
          messageTypeID:1,
          fileIDs,
          createdDate:moment.utc().format("YYYY-MM-DD HH:mm:ss")
        }
        socket.emit(EVENTS_MESSAGE.CREATED,messageData,(data) => {});  

        resolve(messageData);
          
      } catch (error) {
        reject(error);
      }

    });   
  },

  /**
   * send message to server to set unread messages in conversation as read
   * @param {String} visitorID - visitorID (conversation)
   * @returns {Promise} - Promise
   */
   messagesRead:() => {
    return new Promise(async(resolve,reject) => {
        try {
        const socket = getWebSocket().socket;  

        const conversationData	= conversationCache.get();
        const visitorData			= visitorCache.get();
        let data = {
          conversationID:conversationData.conversationID,
          visitorID:visitorData.visitorID,
          operatorID:null
        }

        socket.emit(EVENTS_MESSAGE.MESSAGES_READ,data,(data) => {});  


        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  },

  /**
   * send message to server to set selectedRating
   * @param {String} messageID - messageID 
   * @param {Int} ratedValue - selected rating value 
   * @returns {Promise} - Promise
   */
   sendRating:(messageID,ratedValue) => {
    return new Promise(async(resolve,reject) => {
        try {
        const socket = getWebSocket().socket;  

        let data = {
          messageID,
          ratedValue
        }

        socket.emit(EVENTS_MESSAGE.RATING_SELECTED,data,(data) => {});  


        resolve(data);
          
      } catch (error) {
        reject(error);
      }

    });   
  },

}
