import { accountCache } from '../../../Library/Cache/accountCache';
import { EVENTS_ACCOUNT } from '../Library/accountEvents';

  const registerAccountHandlers = (socket) => {

      const statusChanged = (data) => {
        accountCache.set({status:data.status});
      }
    
    
      socket.on(EVENTS_ACCOUNT.STATUS_CHANGED, statusChanged);
  }

export default registerAccountHandlers;