import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink, concat } from "apollo-link";

let apolloClient;

export const getApolloClient = () => {
    if (apolloClient) {
        return apolloClient;
    }

    // inicializace Cache - dá se do této cache ukládat hodnoty pro příští použití
    const cache = new InMemoryCache();
    // URl adresa na server pro graphql
    const httpLink = new createUploadLink({
        uri: process.env.REACT_APP_SERVER_URL,
    });
    /*
    //prostředník, který při každém requestu zasílá token na server z localstorage
    const authMiddleware = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext({
            headers: {
                authorization: localStorage.getItem('token') || null,
            }
        });
    
        return forward(operation);
    });
    */

    //Apollo klient, který se přihlašuje na server
    apolloClient = new ApolloClient({
        cache,
        //link: concat(authMiddleware, httpLink),
        link: httpLink,
    });

    return apolloClient;
};
