import { makeVar } from '@apollo/client';
  
export const initialParamsVar = makeVar({
    referrer:"",
    url:"",
    pageTitle:""
});

export const initialParamsCache = {
    get: () => {
        return initialParamsVar();
    },
    set: (data) => {
        const newData = { 
            ...(data.referrer 	&& { referrer: data.referrer }),
            ...(data.url 	    && { url: data.url }),
            ...(data.pageTitle 	&& { pageTitle: data.pageTitle }),
        }
        initialParamsVar({...initialParamsVar(),...newData});
    }
}