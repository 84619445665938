import { useEffect } from "react";
import moment from 'moment';
import translate from '../Modules/Translations/index';
import {floor} from 'mathjs';
import { Store } from 'react-notifications-component';

export function addNotification(type,text,title = null,duration = 7000) {
    Store.addNotification({
        title: title,
        message: text,
        type: type,
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
        duration,
        //onScreen true
        }
    });
}
export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function getUrlParams(){
    const params = window.location.pathname.split("/");
    return{
        chatboxID:params[1],
        visitorID:params[2],
    }
}

// Hook
export function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }
  export function playSound(type,volume = 1){
      let mp3 = null;
  
      switch (type) {
          case "incoming-message":
              mp3 = require('../Public/Sounds/incoming_message.mp3').default;
              break;
          case "incoming-message-visible":
              mp3 = require('../Public/Sounds/incoming_message_visible.mp3').default;
              break;
      
          default:
              break;
      }
      if(mp3 != null){
          var audio = new Audio(mp3);
          audio.volume = volume;
          audio.play();
      }
  }
  
  export function middleEllipsis(str,startCharCount,endCharCount) {
    if (str.length > (startCharCount + endCharCount)) {
        return str.substr(0, startCharCount) + '...' + str.substr(str.length-endCharCount, str.length);
    }
    return str;
  }


export function getMessageTimeString(dateTime,showNow = false){
    const nowDate       = moment();
    const messageDate   = moment.utc(dateTime);

    const nowTimestamp      = nowDate.unix();
    const messageTimestamp  = messageDate.unix();

    const diffDuration = nowTimestamp - messageTimestamp;
    
    if(diffDuration < 60){
        return showNow ? translate.t("Now") : "";
    }else if(diffDuration < 3600){
        const minuteCount = floor(diffDuration / 60);
        return translate.t("BeforeMinute",{beforeMinute:minuteCount}); 
    }else if(diffDuration < 86400){
        const hourCount = floor(diffDuration / 60 / 60);
        return translate.t("BeforeHour",{beforeHour:hourCount}); 
    }else if (diffDuration < 172800){
        return translate.t("Yesterday") + " " + messageDate.local().format("HH:mm"); 
    }else{
        return messageDate.format("L")
    }
}

export const getMessagesDateSeparator = (lastMessageDate,messageDate) => {
    if(!moment(lastMessageDate).isSame(messageDate,"day")){

        let displayDate      = "";
        const nowDate       = moment();
        const mesgDate      = moment.utc(messageDate);

        if(nowDate.isSame(mesgDate,"day")){
            displayDate = translate.t("DateSeparatorToday");
        }else if(nowDate.subtract(1,"day").isSame(mesgDate,"day")){
            displayDate = translate.t("DateSeparatorYesterday");
        }else{
            displayDate = mesgDate.format("L");
        }

        return(
            <div className='messages-date-separator'>
                <div className='separator-date'>
                    {displayDate}
                </div>
            </div>
        );
    }else{
        return null;
    }
}

export const openLinkInParentWindow = (link,newPage = false) => {
    window.parent.postMessage(["openLink", link,newPage], "*");
}

export const getMessageGroupedInfo = (messageList,index) => {
    let groupedInfo = {
        isGrouped:false,
        isFirst:false,
        isLast:false
    }
    
    if(messageList[index].messageTypeID == 1){
        if(messageList[index].fromOperator){
            if(!!messageList[index + 1] && messageList[index + 1].messageTypeID == 1 && messageList[index + 1].operatorID == messageList[index].operatorID && moment(messageList[index + 1].createdDate).subtract(1,"minutes").unix() <= moment(messageList[index].createdDate).unix()){
                
                groupedInfo.isGrouped = true;
                if(!messageList[index - 1] || messageList[index - 1].messageTypeID != 1 || (messageList[index - 1].operatorID != messageList[index].operatorID || moment(messageList[index - 1].createdDate).add(1,"minutes").unix() <= moment(messageList[index].createdDate).unix())){
                    groupedInfo.isFirst = true;
                }
                
            }else if(!!messageList[index - 1] && messageList[index - 1].messageTypeID == 1 && messageList[index - 1].operatorID == messageList[index].operatorID && moment(messageList[index - 1].createdDate).add(1,"minutes").unix() >= moment(messageList[index].createdDate).unix()){
               
                groupedInfo.isGrouped = true;
                if(!messageList[index + 1] || messageList[index + 1].messageTypeID != 1 || (messageList[index + 1].operatorID != messageList[index].operatorID || moment(messageList[index + 1].createdDate).subtract(1,"minutes").unix() >= moment(messageList[index].createdDate).unix())){
                    groupedInfo.isLast = true;
                }

            }
        }else{
            if(!!messageList[index + 1] && messageList[index + 1].messageTypeID == 1 && messageList[index + 1].visitorID == messageList[index].visitorID && moment(messageList[index + 1].createdDate).subtract(1,"minutes").unix() <= moment(messageList[index].createdDate).unix()){
                
                groupedInfo.isGrouped = true;
                if(!messageList[index - 1] || messageList[index - 1].messageTypeID != 1 || (messageList[index - 1].visitorID != messageList[index].visitorID || moment(messageList[index - 1].createdDate).add(1,"minutes").unix() <= moment(messageList[index].createdDate).unix())){
                    groupedInfo.isFirst = true;
                }
                
            }else if(!!messageList[index - 1] && messageList[index - 1].messageTypeID == 1 && messageList[index - 1].visitorID == messageList[index].visitorID && moment(messageList[index - 1].createdDate).add(1,"minutes").unix() >= moment(messageList[index].createdDate).unix()){
               
                groupedInfo.isGrouped = true;
                if(!messageList[index + 1] || messageList[index + 1].messageTypeID != 1 || (messageList[index + 1].visitorID != messageList[index].visitorID || moment(messageList[index + 1].createdDate).subtract(1,"minutes").unix() >= moment(messageList[index].createdDate).unix())){
                    groupedInfo.isLast = true;
                }

            }
        }
    }

    return groupedInfo;
}
