import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ChatClosed from './ChatClosed';
import ChatOpened from './ChatOpened';
import { bootstrapApp, setMessagesRead } from '../../../Library/chat';
import { useReactiveVar } from '@apollo/client';
import { chatboxCache, chatboxVar } from '../../../Library/Cache/chatboxCache';
import { visitorVar } from '../../../Library/Cache/visitorCache';
import { useResizeDetector } from 'react-resize-detector';
import visitorEmiters from '../../Websocket/Emiters/visitorEmiters';
import ReactTooltip from 'react-tooltip';

const ChatBox = (props) => {

	const chatboxData			= useReactiveVar(chatboxVar);
    const visitorData 			= useReactiveVar(visitorVar);
	
	const onResize = useCallback((width,height) => {
		window.parent.postMessage(["setDimesions", width,height], "*");
	}, []);
  
  	const { ref:chatRef, width, height } = useResizeDetector({ onResize });


	useEffect(() => {

		window.addEventListener("message", onOutsideMessage);
		bootstrapApp(props.referrer,props.url,props.pageTitle);	

		return function cleanup() {
			window.removeEventListener("message", onOutsideMessage, false);
		}
	},[]);
	
	

	const onOutsideMessage = (e) => {
		if(e.data[0] == 'clickedOutsideChat') {  
			chatboxCache.set({isFocused:false});
		}
	}
	const openCloseChat = (opened) => {
		chatboxCache.set({isOpened:opened});
		visitorEmiters.changeChatOpenState(opened);
		window.parent.postMessage(["setIframeContainerStyle", opened], "*");
	}
	const chatFocused = () => {
		if(!chatboxCache.get().isFocused){
			chatboxCache.set({isFocused:true});
			if(visitorData.unreadMessageCount > 0){
				setMessagesRead();
			}
		}
	}

    return (
		<div ref={chatRef}>
			{!!chatboxData && chatboxData.initiated &&
				<div className={`chat ${!!chatboxData.pageWidth && chatboxData.pageWidth < 576 ? "mobile" : ""}`} onClick={() => chatFocused()}>
					{chatboxData.isOpened ?
						<ChatOpened onClosed={() => openCloseChat(false)}/>
						:
						<div onClick={() => openCloseChat(true)}>
							<ChatClosed />
						</div>				
					}
					<ReactTooltip id="message-time-tooltip" effect="solid" place="left" arrowColor="transparent"/>
					<ReactTooltip id="message-time-tooltip-right" effect="solid" place="right" arrowColor="transparent"/>
				</div>
			}
			
		</div>
    );
}

export default ChatBox;
